<template>
  <div style="width: 100%">
    <b-tabs @input="tabChanged" content-class="mt-3">
      <b-tab :title="'Property (' + paidProperties + ')'" active>
        <div class="container">
          <b-table
            :busy="isBusy"
            ref="table"
            :filter="search"
            :current-page="currentPage"
            :per-page="perPage"
            :tbody-tr-class="'transparentTr'"
            :fields="fields"
            :items="itemsProvider"
            show-empty
          >
            <template #empty>
              <div
                v-if="processing"
                style="color: #55904e"
                class="text-center my-2"
              >
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
              <div class="text-center" v-else>No Properties Found</div>
            </template>
            <template #table-busy>
              <div style="color: #55904e" class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(custom)="row">
              <simple-list-property
                @componentMessages="messageStatusCheck($event)"
                @componentOffers="offerStatusCheck($event)"
                :key="row.item.id"
                :property="row.item"
                :show="show[row.item.id]"
                :currentComponent="currentComponent"
              >
                <template #descriptionTitle="{ property }">
                  <span
                    style="text-align: justify; margin-top: 2px !important"
                    class="mt-n4"
                  >
                    {{
                      property.description
                        ? property.description[0]
                          ? `${
                              property.description[0].value[
                                Object.keys(property.description[0].value)[0]
                              ]
                            }`
                          : ""
                        : ""
                    }}</span
                  >
                </template>
                <template v-if="screenWidth >= 760" #buttons>
                  <button
                    v-if="row.item.status !== 2"
                    @click="changeView('ListingTabsOffers', row.item.id)"
                    style="
                      width: 25%;
                      padding: 10px 0px;
                      border: none;
                      background-color: #618c57;
                      color: #fff;
                      border-radius: 5px;
                      font-size: 15px;
                    "
                    class="notification res-button-how"
                  >
                    Offers
                    <span v-if="isNewOffer(row.item)" class="badge">{{
                      isNewOffer(row.item)
                    }}</span>
                  </button>
                  <button
                    style="
                      width: 25%;
                      padding: 10px 0px;
                      border: none;
                      background-color: #618c57;
                      color: #fff;
                      border-radius: 5px;
                      font-size: 15px;
                    "
                    class="notification res-button-how"
                    @click="changeView('ListingTabsSolicitor', row.item.id)"
                    v-else
                  >
                    Solicitor Details
                  </button>
                  <button
                    @click="changeView('ListingTabsMessages', row.item.id)"
                    style="
                      width: 25%;
                      padding: 10px 0px;
                      border: none;
                      background-color: #618c57;
                      color: #fff;
                      border-radius: 5px;
                      font-size: 15px;
                    "
                    class="notification res-button-how"
                  >
                    Messages
                    <span v-if="anyUnreadMessage(row.item)" class="badge">{{
                      anyUnreadMessage(row.item)
                    }}</span>
                  </button>
                </template>
                <template v-else #buttonsAlt>
                  <button
                    v-if="row.item.status !== 2"
                    @click="changeView('ListingTabsOffers', row.item.id)"
                    style="
                      width: 100%;
                      margin-top: 5px;
                      margin-bottom: 15px;
                      padding: 10px 0px;
                      border: none;
                      background-color: #618c57;
                      color: #fff;
                      border-radius: 5px;
                      font-size: 15px;
                    "
                    class="notification res-button-how"
                  >
                    Offers
                    <span v-if="isNewOffer(row.item)" class="badge">{{
                      isNewOffer(row.item)
                    }}</span>
                  </button>
                  <button
                    style="
                      width: 100%;
                      margin-top: 5px;
                      margin-bottom: 15px;
                      padding: 10px 0px;
                      border: none;
                      background-color: #618c57;
                      color: #fff;
                      border-radius: 5px;
                      font-size: 15px;
                    "
                    class="notification res-button-how"
                    @click="changeView('ListingTabsSolicitor', row.item.id)"
                    v-else
                  >
                    Solicitor Details
                  </button>
                  <button
                    @click="changeView('ListingTabsMessages', row.item.id)"
                    style="
                      width: 100%;
                      margin-top: 5px;
                      margin-bottom: 15px;
                      padding: 10px 0px;
                      border: none;
                      background-color: #618c57;
                      color: #fff;
                      border-radius: 5px;
                      font-size: 15px;
                    "
                    class="notification res-button-how"
                  >
                    Messages
                    <span v-if="anyUnreadMessage(row.item)" class="badge">{{
                      anyUnreadMessage(row.item)
                    }}</span>
                  </button>
                </template>
                <template #actions>
                  <b-modal
                  @shown="setHeight(row.item.id)"
                    centered
                    :ref="`relist${row.item.id}`"
                    :id="`relist${row.item.id}`"
                    title="Alert"
                  >
                    <p>Are you sure you want to remove this listing?</p>
                    <template #modal-footer>
                      <b-button
                        @click="$refs[`relist${row.item.id}`].hide()"
                        variant="secondary"
                        size="md"
                        class="listing-button-color-secondary btn btn-primary"
                      >
                        Cancel
                      </b-button>
                      <b-button
                        @click="relist(row.item.id)"
                        variant="primary"
                        size="md"
                        class="listing-button-color btn btn-primary"
                      >
                        Relist
                      </b-button>
                    </template>
                  </b-modal>
                  <b-modal
                  @shown="setHeightDelete(row.item.id)"
                    centered
                    :ref="`deleteModal${row.item.id}`"
                    :id="`deleteModal${row.item.id}`"
                    title="Alert"
                  >
                    <p>
                      Are you sure you want to permanently delete this listing?
                    </p>
                    <template #modal-footer>
                      <b-button
                        @click="$refs[`deleteModal${row.item.id}`].hide()"
                        variant="secondary"
                        size="md"
                        class="listing-button-color-secondary btn btn-primary"
                      >
                        Cancel
                      </b-button>
                      <b-button
                        @click="deleteList(row.item)"
                        variant="primary"
                        size="md"
                        class="listing-button-color btn btn-primary"
                      >
                        Delete
                      </b-button>
                    </template>
                  </b-modal>
                  <img
                    v-if="
                      _.get(
                        row.item.propertyPackage,
                        `[${
                          _.defaultTo(
                            _.get(row.item.propertyPackage, 'length', null),
                            1
                          ) - 1
                        }].remainingDays`,
                        false
                      ) > 5
                    "
                    v-b-popover.hover.right="
                      `Payment not needed. ${_.get(
                        row.item.propertyPackage,
                        `[${
                          _.defaultTo(
                            _.get(row.item.propertyPackage, 'length', null),
                            1
                          ) - 1
                        }].remainingDays`,
                        false
                      )} days remaining.`
                    "
                    :class="[
                      'w-26',
                      'res-w25',
                      'margin-r-5',
                      'money-pound',
                      _.get(
                        row.item.propertyPackage,
                        `[${
                          _.defaultTo(
                            _.get(row.item.propertyPackage, 'length', null),
                            1
                          ) - 1
                        }].isPremiumListing`,
                        false
                      )
                        ? 'pay-white-filled'
                        : '',
                    ]"
                    :src="
                      require(`../assets/images/icons/Vencasa-Payment-Icon-${
                        _.get(
                          row.item.propertyPackage,
                          `[${
                            _.defaultTo(
                              _.get(row.item.propertyPackage, 'length', null),
                              1
                            ) - 1
                          }].isPremiumListing`,
                          false
                        )
                          ? 'White'
                          : 'Green'
                      }.webp`)
                    "
                  />
                  <img
                    v-else
                    v-b-popover.hover.right="`Payment needed. Relist Now.`"
                    @click="decideBetweenPackageOrPayment(row.item)"
                    :class="[
                      'w-26',
                      'res-w25',
                      'margin-r-5',
                      'money-pound',
                      _.get(
                        row.item.propertyPackage,
                        `[${
                          _.defaultTo(
                            _.get(row.item.propertyPackage, 'length', null),
                            1
                          ) - 1
                        }].isPremiumListing`,
                        false
                      )
                        ? 'pay-white-filled'
                        : '',
                    ]"
                    :src="
                      require(`../assets/images/icons/Vencasa-Payment-Icon-${
                        _.get(
                          row.item.propertyPackage,
                          `[${
                            _.defaultTo(
                              _.get(row.item.propertyPackage, 'length', null),
                              1
                            ) - 1
                          }].isPremiumListing`,
                          false
                        )
                          ? 'White'
                          : 'Green'
                      }.webp`)
                    "
                  />
                  <img
                    @click="$router.push(`/edit-property/${row.item.id}`)"
                    :class="[
                      'w-30',
                      'res-w30',
                      'edit-box',
                      _.get(
                        row.item.propertyPackage,
                        `[${
                          _.defaultTo(
                            _.get(row.item.propertyPackage, 'length', null),
                            1
                          ) - 1
                        }].isPremiumListing`,
                        false
                      )
                        ? 'edit-white-filled'
                        : '',
                    ]"
                    :src="
                      require(`../assets/images/icons/Vencasa-edit-icon-${
                        _.get(
                          row.item.propertyPackage,
                          `[${
                            _.defaultTo(
                              _.get(row.item.propertyPackage, 'length', null),
                              1
                            ) - 1
                          }].isPremiumListing`,
                          false
                        )
                          ? 'White'
                          : 'Green'
                      }.webp`)
                    "
                  />
                  <img
                    @click="$refs[`deleteModal${row.item.id}`].show()"
                    :class="[
                      'w-30',
                      'margin-r-5',
                      'res-w30',
                      'chat-delete',
                      _.get(
                        row.item.propertyPackage,
                        `[${
                          _.defaultTo(
                            _.get(row.item.propertyPackage, 'length', null),
                            1
                          ) - 1
                        }].isPremiumListing`,
                        false
                      )
                        ? 'chat-white-filled'
                        : '',
                    ]"
                    :src="
                      require(`../assets/images/icons/Vencasa-Chat-Delete-${
                        _.get(
                          row.item.propertyPackage,
                          `[${
                            _.defaultTo(
                              _.get(row.item.propertyPackage, 'length', null),
                              1
                            ) - 1
                          }].isPremiumListing`,
                          false
                        )
                          ? 'White'
                          : 'Green'
                      }.webp`)
                    "
                  />
                  <br class="res-hide-big" />
                </template>
              </simple-list-property>
            </template>
          </b-table>
          <div class="d-flex justify-content-center">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </b-tab>
      <b-tab :title="'Drafts (' + draftLength + ')'">
        <Drafts
          :unPaidProperties="
            _.filter(
              properties,
              (property) =>
                !_.get(
                  property.propertyPackage,
                  `[${
                    _.defaultTo(
                      _.get(property.propertyPackage, 'length', null),
                      1
                    ) - 1
                  }]`,
                  false
                )
            )
          "
          v-if="isDraftActive"
        ></Drafts>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import moment from "moment";
const SimpleListProperty = () =>
  import(
    /* webpackChunkName: "Sb6uxNl0Mp" */ "../components/simple-list-property"
  );
const drafts = () =>
  import(/* webpackChunkName: "oEgNX1L2na" */ "../components/drafts.vue");
import { mapGetters } from "vuex";
import { formatter } from "../utils";
import _ from "lodash";

export default {
  data() {
    return {
      draftLength: 0,
      paidProperties: [],
      screenWidth: window.innerWidth,
      propertyMessages: {},
      propertyOffers: {},
      isDraftActive: false,
      date: "",
      time: "",
      duration: "",
      timeSlots: [],
      repeatFor: 1,
      events: [],
      startDate: "",
      property: {},
      value: "",
      startTime: "",
      endTime: "",
      options: [],
      show: {},
      currentComponent: "",
      search: "",
      isBusy: false,
      currentPage: 1,
      rows: 9,
      perPage: this.showPerPage,
      fields: [
        {
          key: "custom",
          thClass: ["d-none"],
          tdClass: "remove-border",
        },
      ],
    };
  },
  validations: {
    date: {
      isDateValid: (value) => {
        return moment(value).isValid();
      },
    },
    time: {
      isTimeValid: (value) => {
        return moment(value, "h:mm A").isValid();
      },
    },
  },
  watch: {
    sorting() {
      this.$refs.table.refresh();
    },
    showPerPage(val) {
      this.perPage = val;
    },
    propertyDrafts() {
      this.draftLength = this.propertyDrafts.length + (this.properties.length - this.paidProperties);
    },
    properties(val) {
      this.paidProperties = this.properties.filter((item) =>
        _.get(
          item.propertyPackage,
          `[${
            _.defaultTo(_.get(item.propertyPackage, "length", null), 1) - 1
          }]`,
          false
        )
      ).length;
      this.rows = this.paidProperties;
      this.$refs.table.refresh();
    },
  },
  computed: {
    _() {
      return _;
    },
    ...mapGetters({
      authUser: "auth",
      propertyDrafts: "getPropertyDraft",
    }),
  },
  mounted() {
    this.paidProperties = this.properties.filter((item) =>
      _.get(
        item.propertyPackage,
        `[${_.defaultTo(_.get(item.propertyPackage, "length", null), 1) - 1}]`,
        false
      )
    ).length;
    this.rows = this.paidProperties;
    if (!this.propertyDrafts)
      this.$store.dispatch("getPropertyDraft").then((res) => {
        if (res == true) {
          this.draftLength = this.propertyDrafts.length + (this.properties.length - this.paidProperties);
        }
      });
    else {
      this.draftLength = this.propertyDrafts.length + (this.properties.length - this.paidProperties);
    }
    for (let i = 5; i <= 60; i = i + 5) {
      this.options = [...this.options, { tag: `${i} minutes`, value: i }];
    }
  },
  props: ["properties", "processing", "sorting", "showPerPage"],
  methods: {
    setHeightDelete(propertyId){
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_content_`)
        .setAttribute(
          "style",
          "height : auto !important; display: block !important"
        );
      document
        .querySelector("#deleteModal" + propertyId)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_body_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_backdrop_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_outer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_header_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_footer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_title_`)
        .setAttribute("style", "display: block !important");
    },
    setHeight(propertyId){
      document
        .querySelector(`#relist${propertyId}___BV_modal_content_`)
        .setAttribute(
          "style",
          "height : auto !important; display: block !important"
        );
      document
        .querySelector("#relist" + propertyId)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#relist${propertyId}___BV_modal_body_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#relist${propertyId}___BV_modal_backdrop_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#relist${propertyId}___BV_modal_outer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#relist${propertyId}___BV_modal_header_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#relist${propertyId}___BV_modal_footer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#relist${propertyId}___BV_modal_title_`)
        .setAttribute("style", "display: block !important");
    },
    relist(id) {
      this.$store.dispatch("relist", id).then(async (res) => {
        if (res == true) {
          this.$refs[`relist${id}`].hide();
          this.$store.commit("setMessage", "Property relisted");
          await this.$store.dispatch("getUserProperties");
          this.$refs.table.refresh();
        } else {
          this.$refs[`relist${id}`].hide();
          this.$store.commit("setMessage", "Property not relisted");
        }
      });
    },
    messageStatusCheck(messageObject) {
      this.$set(
        this.propertyMessages,
        `${messageObject.property.id}`,
        messageObject.messages
      );
      this.anyUnreadMessage(messageObject.property);
    },
    offerStatusCheck(offerObject) {
      this.$set(
        this.propertyOffers,
        `${offerObject.property.id}`,
        offerObject.offers
      );
      this.isNewOffer(offerObject.property);
    },
    fomatter: formatter,
    tabChanged(index) {
      index == 1 ? (this.isDraftActive = true) : (this.isDraftActive = false);
    },
    timeFromNow(date) {
      return moment(date).fromNow();
    },
    openViewingModal(property) {
      this.$refs[`modal-add-viewing${property.id}`].show();
    },
    async createTimeSlot() {
      this.$v.$touch();
      console.log(this.$v);
    },
    expiryTime(expiry) {
      var today = moment();
      var expiredAt = moment(expiry);
      let duration = moment.duration(expiredAt.diff(today));
      return duration.humanize();
    },
    isNewOffer(property) {
      let matchedOffer = (
        this.propertyOffers[`${property.id}`]
          ? this.propertyOffers[`${property.id}`]
          : property.offers
      ).filter((offer) => {
        return offer.isRead == 0 && offer.lastOfferBy !== this.authUser.buyer_seller_id;
      });
      console.log({matchedOffer})
      return matchedOffer.length ? matchedOffer.length : false;
    },
    decideBetweenPackageOrPayment(property) {
      this.$router.push(
        `/add-property/?propertyId=${property.id}&pending=package`
      );
    },
    anyUnreadMessage(property) {
      let matchedMessage = (
        this.propertyMessages[`${property.id}`]
          ? this.propertyMessages[`${property.id}`]
          : property.messages
      ).filter((message) => {
        return (
          message.status == "unread" &&
          message.userId != this.authUser.buyer_seller_id
        );
      });
      return matchedMessage.length ? matchedMessage.length : false;
    },
    anyPendingViewings(property) {
      let matchedViewing = property.viewing.find((viewing) => {
        return viewing.status == "pending";
      });
      return matchedViewing ? true : false;
    },
    changeView(view, id) {
      if (view != this.currentComponent) {
        this.currentComponent = view;
        for (let key in this.show) {
          this.$set(this.show, `${key}`, false);
        }
        this.$set(this.show, `${id}`, true);
      } else {
        this.$set(this.show, `${id}`, !this.show[id]);
      }
    },
    itemsProvider(ctx, callback) {
      let offset = (this.currentPage - 1) * this.perPage;
      let items =
        _.orderBy(
          this.properties.slice(offset, this.perPage * this.currentPage),
          [this.sorting.split("|")[0]],
          [this.sorting.split("|")[1]]
        ) || [];
      items = items.filter((item) =>
        _.get(
          item.propertyPackage,
          `[${
            _.defaultTo(_.get(item.propertyPackage, "length", null), 1) - 1
          }]`,
          false
        )
      );
      this.$emit("total", items.length);
      return items;
    },
    timeFromNow(property) {
      return moment(property.createdAt).fromNow();
    },
    async deleteList(property) {
      this.$store.commit("setShowLoader", true);
      await this.$store
        .dispatch("deleteProperty", property.id)
        .then(async (res) => {
          this.$refs[`deleteModal${property.id}`].hide();
          this.$store.commit("setShowLoader", false);
          if (res == true) {
            await this.$store.dispatch("getUserProperties").then((res) => {
              if (res.status == 400)
                this.$store.commit("setUserProperties", []);
            });
          }
        });
    },
  },
  components: {
    Drafts: drafts,
    SimpleListProperty,
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>
.margin-r-5 {
  margin-right: 5px;
}

@media (min-width: 300px) and (max-width: 700px) {
  .res-w30 {
    width: 25px !important;
    height: 25px !important;
  }

  .res-w25 {
    width: 25px !important;
    height: 20px !important;
  }
}

div >>> .page-item.active .page-link {
  background-color: #55904e;
  border-color: #55904e;
}
div >>> .page-link {
  color: #55904e;
}

div >>> .remove-border {
  border-top: none;
  padding: 0px;
}
.transparentTr {
  background-color: transparent !important;
}

.pending-hover:hover {
  cursor: pointer;
  color: red;
}

.stroke-transparent {
  -webkit-text-stroke: 1px #000;
  -webkit-text-fill-color: transparent;
}

.stroke-transparent-premium {
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: transparent;
}

div >>> .marked-cell {
  background-color: yellow;
}

div >>> .form-control {
  width: unset;
  display: unset;
  padding-top: 8px;
  padding-bottom: 9px;
  top: 6px;
}

div >>> .vuecal__menu,
.vuecal__cell-events-count {
  background-color: #548f4d;
}

.small-text {
  font-size: small !important;
}

div >>> .vuecal__event--dragging {
  background-color: rgba(60, 60, 60, 0.3);
  border: none;
}

div >>> .vuecal__event.vuecal__event--static {
  transition: 0.1s opacity;
}
div >>> .vuecal__event-delete {
  transform: none;
}

.viewing-margin {
  margin-top: 25px;
}

div >>> .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: white !important;
  background-color: #548f4d !important;
  border-color: #dee2e6 #dee2e6 #fff;
}
div >>> .nav-link {
  color: #666;
}

div >>> .nav-item {
  width: 120px;
  text-align: center;
}

div >>> .nav-tabs {
  margin-left: 14px;
  margin-right: 14px;
}

div >>> .nav-link {
  border: 1px solid #dee2e6;
}

.notification {
  background-color: #555;
  color: white;
  text-decoration: none;
  padding: 15px 26px;
  position: relative;
  display: inline-block;
  border-radius: 2px;
}

.notification:hover {
  background: red;
}

.notification .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 7px;
  border-radius: 50%;
  background: red;
  color: white;
}

div >>> td {
  border: none;
}

.c-white {
  color: white !important;
}

@media (min-width: 700px) and (max-width: 4146px) {
  .res-hide-big {
    display: none !important;
  }
}

.w-30 {
  width: 30px;
  height: 30px;
}

.w-25{
  width: 30px;
  height: 26px;
}
.w-26{
  width: 30px;
  height: 26px;
  margin-top: 2px;
}

.money-pound:hover {
  content: url("../assets/images/icons/Vencasa-Payment-Icon-Filled.webp");
}
.edit-box:hover {
  content: url("../assets/images/icons/Vencasa-edit-icon-fill.webp");
}
.chat-delete:hover {
  content: url("../assets/images/icons/Vencasa-chat-delet-fill.webp");
}

.chat-white-filled:hover {
  content: url("../assets/images/icons/chat-white-filled.webp");
}

.edit-white-filled:hover {
  content: url("../assets/images/icons/edit-white-filled.webp");
}

.pay-white-filled:hover {
  content: url("../assets/images/icons/pay-white-filled.webp");
}
</style>
